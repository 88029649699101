import Swal from 'sweetalert2';

const flashMessage = (title, message, type) => {
	let titles = {
		success: 'Úspěch',
		error: 'Chyba',
	};

	Swal.fire({
		icon: type === 'danger' ? 'error' : type,
		title: title,
		text: message,
		customClass: {
			container: 'flash-message-modal',
			popup: 'flash-message-modal-popup',
			confirmButton: 'flash-message-modal-button-confirm',
			title: 'flash-message-modal-title',
			htmlContainer: 'flash-message-modal-message',
		},
	});
};

export { flashMessage };

//import { Toast } from 'bootstrap';
//
//const flashMessage = (message, type) => {
//	let titles = {
//		success: 'Úspěch',
//		error: 'Chyba',
//	};
//	let messageHtml = document.createElement('div');
//	messageHtml.classList.add('toast');
//	messageHtml.innerHTML =
//		'<div role="alert" aria-live="assertive" aria-atomic="true">' +
//		'<div class="toast-header text-white bg-' +
//		type +
//		'">' +
//		'<strong class="me-auto">' +
//		titles[type] +
//		'</strong>' +
//		// '<small>11 mins ago</small>' +
//		'<button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>' +
//		'</div>' +
//		'<div class="toast-body">' +
//		message +
//		'</div>' +
//		'</div>';
//
//	document.getElementById('toastPlacement').appendChild(messageHtml);
//	new Toast(messageHtml).show();
//};
//
//export { flashMessage };
