import '../styles/bootstrap.scss';

import { Dropdown } from 'bootstrap';

let dropdownElementList = [].slice.call(
	document.querySelectorAll('[data-bs-toggle="dropdown"]'),
);
dropdownElementList.map(el => new Dropdown(el));

// Flash messages
import { flashMessage } from './components/flashMessage.js';
window.flashMessage = flashMessage;
